import { DefaultTheme } from 'styled-components';

export const light: DefaultTheme = {
  grid: 8,
  colors: {
    accent: '#E16560',
    highlight: '#D4F0F2',
    overlay: '#1493A4',
    background: '#FFFFFF',
    copy: '#303A41',
  },
  type: {
    heading: {
      fontSize: 32,
      lineHeight: 34,
    },
    subheading: {
      fontSize: 20,
      lineHeight: 24,
    },
    navLink: {
      fontSize: 26,
      lineHeight: 24,
    },
    body: {
      fontSize: 16,
      lineHeight: 18,
    },
    cta: {
      fontSize: 14,
      lineHeight: 18,
    },
    input: {
      fontSize: 16,
      lineHeight: 18,
    },
    smallPrint: {
      fontSize: 10,
      lineHeight: 14,
    },
  },
};
